import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import { Helmet } from 'react-helmet';

import CookieBanner from 'components/CookieBanner';
import { useI18n } from 'context/I18nContext';
import { buildCanonical } from 'utils';

import { Footer } from './Footer';
import Freshchat from './Freshchat';
import { Header } from './Header';

const query = graphql`
  query IntlQuery {
    site {
      siteMetadata {
        siteUrl
        intl {
          defaultLocale
          locales {
            path
            lcid
          }
        }
        chat {
          freshchatEnabled
        }
      }
    }
  }
`;

export const Layout: React.FC<{ locale: string }> = ({ children, locale }) => {
  const [hasConsentValue, setConsentValue] = useState(false);

  useEffect(() => {
    const consentValue = getCookieConsentValue();

    setConsentValue(consentValue === 'true');
  }, []);

  const handleCookieConsent = (value: boolean) => {
    setConsentValue(value);
  };

  const {
    site: {
      siteMetadata: {
        intl,
        siteUrl,
        chat: { freshchatEnabled },
      },
    },
  } = useStaticQuery(query);

  const { locale: currentLocale, setLocale } = useI18n();

  useEffect(() => {
    let isMounted = true;

    if (!isMounted && currentLocale === locale) return;
    setLocale(locale || 'es');

    return () => {
      isMounted = false;
    };
  }, [locale]);

  return (
    <>
      <Helmet>
        {intl.locales.map((lang: any) => {
          const href = buildCanonical(lang, intl.defaultLocale, siteUrl, '');
          return (
            <link
              key={lang.lcid}
              rel={lang.lcid === intl.defaultLocale ? 'canonical' : 'alternate'}
              href={href}
              hrefLang={lang.lcid}
            />
          );
        })}
      </Helmet>
      <Header />
      {children}
      <Footer />
      <CookieBanner onConsent={handleCookieConsent} />
      {hasConsentValue && freshchatEnabled ? <Freshchat /> : null}
    </>
  );
};
